const workContent = [
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-425%2Fjpegxl%2F2018-02-11-brittanyjane-425_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-425%2Fjpegxl%2F2018-02-11-brittanyjane-425_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-425%2Fjpegxl%2F2018-02-11-brittanyjane-425_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-425%2Fjpegxl%2F2018-02-11-brittanyjane-425_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-425%2Fjpeg%2F2018-02-11-brittanyjane-425_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-425%2Fjpeg%2F2018-02-11-brittanyjane-425_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-425%2Fjpeg%2F2018-02-11-brittanyjane-425_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-425%2Fjpeg%2F2018-02-11-brittanyjane-425_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-425%2Fjpeg%2F2018-02-11-brittanyjane-425_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },

  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FTEGRAT_Creative_1%2FTEGRAT_Creative_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FTEGRAT_Creative_1%2FTEGRAT_Creative_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FTEGRAT_Creative_1%2FTEGRAT_Creative_1.jpg",
    subKind: "creative",
    shouldAutoPlayOnLoad: true,
    columnSpan: 2,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDeering_Creative_2%2FDeering_Creative_2.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDeering_Creative_2%2FDeering_Creative_2.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDeering_Creative_2%2FDeering_Creative_2.jpg",
    subKind: "creative",
    shouldAutoPlayOnLoad: true,
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FRebel_Creative_1%2FRebel_Creative_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FRebel_Creative_1%2FRebel_Creative_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FRebel_Creative_1%2FRebel_Creative_1.jpg",
    subKind: "creative",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FStrand_Ecomm_1%2FStrand_Ecomm_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FStrand_Ecomm_1%2FStrand_Ecomm_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FStrand_Ecomm_1%2FStrand_Ecomm_1.jpg",
    subKind: "ecomm",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },

  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FMens%20Editorial_Creative%2FMens%20Editorial_Creative.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FMens%20Editorial_Creative%2FMens%20Editorial_Creative.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FMens%20Editorial_Creative%2FMens%20Editorial_Creative.jpg",
    subKind: "creative",
    shouldAutoPlayOnLoad: false,
    columnSpan: 2,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2017-06-10-brittanyjane_gels-326%2Fjpegxl%2F2017-06-10-brittanyjane_gels-326_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2017-06-10-brittanyjane_gels-326%2Fjpegxl%2F2017-06-10-brittanyjane_gels-326_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2017-06-10-brittanyjane_gels-326%2Fjpegxl%2F2017-06-10-brittanyjane_gels-326_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2017-06-10-brittanyjane_gels-326%2Fjpegxl%2F2017-06-10-brittanyjane_gels-326_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2017-06-10-brittanyjane_gels-326%2Fjpeg%2F2017-06-10-brittanyjane_gels-326_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2017-06-10-brittanyjane_gels-326%2Fjpeg%2F2017-06-10-brittanyjane_gels-326_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2017-06-10-brittanyjane_gels-326%2Fjpeg%2F2017-06-10-brittanyjane_gels-326_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2017-06-10-brittanyjane_gels-326%2Fjpeg%2F2017-06-10-brittanyjane_gels-326_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2017-06-10-brittanyjane_gels-326%2Fjpeg%2F2017-06-10-brittanyjane_gels-326_1000w.jpg 1000w",
    loading: "eager",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FAje_Athletica_Creative_1%2FAje_Athletica_Creative_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FAje_Athletica_Creative_1%2FAje_Athletica_Creative_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FAje_Athletica_Creative_1%2FAje_Athletica_Creative_1.jpg",
    subKind: "creative",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDusk_Creative_1%2FDusk_Creative_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDusk_Creative_1%2FDusk_Creative_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDusk_Creative_1%2FDusk_Creative_1.jpg",
    subKind: "creative",
    shouldAutoPlayOnLoad: true,
    columnSpan: 2,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FCommon_Hours_Ecomm_1%2FCommon_Hours_Ecomm_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FCommon_Hours_Ecomm_1%2FCommon_Hours_Ecomm_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FCommon_Hours_Ecomm_1%2FCommon_Hours_Ecomm_1.jpg",
    subKind: "ecomm",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-114%2Fjpegxl%2F2018-02-11-brittanyjane-114_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-114%2Fjpegxl%2F2018-02-11-brittanyjane-114_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-114%2Fjpegxl%2F2018-02-11-brittanyjane-114_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-114%2Fjpegxl%2F2018-02-11-brittanyjane-114_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-114%2Fjpeg%2F2018-02-11-brittanyjane-114_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-114%2Fjpeg%2F2018-02-11-brittanyjane-114_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-114%2Fjpeg%2F2018-02-11-brittanyjane-114_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-114%2Fjpeg%2F2018-02-11-brittanyjane-114_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-114%2Fjpeg%2F2018-02-11-brittanyjane-114_1000w.jpg 1000w",
    loading: "eager",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FSeafolly_Ecomm_2%2FSeafolly_Ecomm_2.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FSeafolly_Ecomm_2%2FSeafolly_Ecomm_2.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FSeafolly_Ecomm_2%2FSeafolly_Ecomm_2.jpg",
    subKind: "ecomm",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FStrand_Ecomm_2%2FStrand_Ecomm_2.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FStrand_Ecomm_2%2FStrand_Ecomm_2.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FStrand_Ecomm_2%2FStrand_Ecomm_2.jpg",
    subKind: "ecomm",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDusk_Creative_2%2FDusk_Creative_2.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDusk_Creative_2%2FDusk_Creative_2.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDusk_Creative_2%2FDusk_Creative_2.jpg",
    subKind: "creative",
    columnSpan: 2,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-009%2Fjpegxl%2F2016-03-05-cassiehubrich-009_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-009%2Fjpegxl%2F2016-03-05-cassiehubrich-009_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-009%2Fjpegxl%2F2016-03-05-cassiehubrich-009_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-009%2Fjpegxl%2F2016-03-05-cassiehubrich-009_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-009%2Fjpeg%2F2016-03-05-cassiehubrich-009_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-009%2Fjpeg%2F2016-03-05-cassiehubrich-009_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-009%2Fjpeg%2F2016-03-05-cassiehubrich-009_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-009%2Fjpeg%2F2016-03-05-cassiehubrich-009_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-009%2Fjpeg%2F2016-03-05-cassiehubrich-009_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_1%2FM.J_Bale_Ecomm_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_1%2FM.J_Bale_Ecomm_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_1%2FM.J_Bale_Ecomm_1.jpg",
    subKind: "ecomm",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-002%2Fjpegxl%2F2016-03-05-cassiehubrich-002_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-002%2Fjpegxl%2F2016-03-05-cassiehubrich-002_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-002%2Fjpegxl%2F2016-03-05-cassiehubrich-002_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-002%2Fjpegxl%2F2016-03-05-cassiehubrich-002_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-002%2Fjpeg%2F2016-03-05-cassiehubrich-002_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-002%2Fjpeg%2F2016-03-05-cassiehubrich-002_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-002%2Fjpeg%2F2016-03-05-cassiehubrich-002_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-002%2Fjpeg%2F2016-03-05-cassiehubrich-002_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-002%2Fjpeg%2F2016-03-05-cassiehubrich-002_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FStrand_Ecomm_3%2FStrand_Ecomm_3.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FStrand_Ecomm_3%2FStrand_Ecomm_3.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FStrand_Ecomm_3%2FStrand_Ecomm_3.jpg",
    subKind: "ecomm",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-scafcomp%2Fjpegxl%2F2016-03-05-cassiehubrich-scafcomp_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-scafcomp%2Fjpegxl%2F2016-03-05-cassiehubrich-scafcomp_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-scafcomp%2Fjpegxl%2F2016-03-05-cassiehubrich-scafcomp_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-scafcomp%2Fjpegxl%2F2016-03-05-cassiehubrich-scafcomp_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-scafcomp%2Fjpeg%2F2016-03-05-cassiehubrich-scafcomp_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-scafcomp%2Fjpeg%2F2016-03-05-cassiehubrich-scafcomp_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-scafcomp%2Fjpeg%2F2016-03-05-cassiehubrich-scafcomp_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-scafcomp%2Fjpeg%2F2016-03-05-cassiehubrich-scafcomp_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2016-03-05-cassiehubrich-scafcomp%2Fjpeg%2F2016-03-05-cassiehubrich-scafcomp_1000w.jpg 1000w",
    loading: "eager",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FClear_Skin_Creative_1%2FClear_Skin_Creative_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FClear_Skin_Creative_1%2FClear_Skin_Creative_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FClear_Skin_Creative_1%2FClear_Skin_Creative_1.jpg",
    subKind: "creative",
    shouldAutoPlayOnLoad: false,
    columnSpan: 2,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-156%2Fjpegxl%2F2018-02-11-brittanyjane-156_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-156%2Fjpegxl%2F2018-02-11-brittanyjane-156_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-156%2Fjpegxl%2F2018-02-11-brittanyjane-156_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-156%2Fjpegxl%2F2018-02-11-brittanyjane-156_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-156%2Fjpeg%2F2018-02-11-brittanyjane-156_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-156%2Fjpeg%2F2018-02-11-brittanyjane-156_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-156%2Fjpeg%2F2018-02-11-brittanyjane-156_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-156%2Fjpeg%2F2018-02-11-brittanyjane-156_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-156%2Fjpeg%2F2018-02-11-brittanyjane-156_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-290%2Fjpegxl%2F2018-11-03-ava-290_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-290%2Fjpegxl%2F2018-11-03-ava-290_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-290%2Fjpegxl%2F2018-11-03-ava-290_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-290%2Fjpegxl%2F2018-11-03-ava-290_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-290%2Fjpeg%2F2018-11-03-ava-290_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-290%2Fjpeg%2F2018-11-03-ava-290_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-290%2Fjpeg%2F2018-11-03-ava-290_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-290%2Fjpeg%2F2018-11-03-ava-290_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-290%2Fjpeg%2F2018-11-03-ava-290_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-624%2Fjpegxl%2F2018-02-11-brittanyjane-624_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-624%2Fjpegxl%2F2018-02-11-brittanyjane-624_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-624%2Fjpegxl%2F2018-02-11-brittanyjane-624_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-624%2Fjpegxl%2F2018-02-11-brittanyjane-624_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-624%2Fjpeg%2F2018-02-11-brittanyjane-624_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-624%2Fjpeg%2F2018-02-11-brittanyjane-624_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-624%2Fjpeg%2F2018-02-11-brittanyjane-624_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-624%2Fjpeg%2F2018-02-11-brittanyjane-624_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-02-11-brittanyjane-624%2Fjpeg%2F2018-02-11-brittanyjane-624_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDeering_Creative_3%2FDeering_Creative_3.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDeering_Creative_3%2FDeering_Creative_3.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDeering_Creative_3%2FDeering_Creative_3.jpg",
    subKind: "creative",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-113%2Fjpegxl%2Fs2-2017-10-08-avahayz-113_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-113%2Fjpegxl%2Fs2-2017-10-08-avahayz-113_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-113%2Fjpegxl%2Fs2-2017-10-08-avahayz-113_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-113%2Fjpegxl%2Fs2-2017-10-08-avahayz-113_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-113%2Fjpeg%2Fs2-2017-10-08-avahayz-113_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-113%2Fjpeg%2Fs2-2017-10-08-avahayz-113_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-113%2Fjpeg%2Fs2-2017-10-08-avahayz-113_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-113%2Fjpeg%2Fs2-2017-10-08-avahayz-113_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-113%2Fjpeg%2Fs2-2017-10-08-avahayz-113_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 2,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Creative_1%2FM.J_Bale_Creative_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Creative_1%2FM.J_Bale_Creative_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Creative_1%2FM.J_Bale_Creative_1.jpg",
    subKind: "creative",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-345%2Fjpegxl%2F2018-11-03-ava-345_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-345%2Fjpegxl%2F2018-11-03-ava-345_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-345%2Fjpegxl%2F2018-11-03-ava-345_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-345%2Fjpegxl%2F2018-11-03-ava-345_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-345%2Fjpeg%2F2018-11-03-ava-345_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-345%2Fjpeg%2F2018-11-03-ava-345_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-345%2Fjpeg%2F2018-11-03-ava-345_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-345%2Fjpeg%2F2018-11-03-ava-345_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-345%2Fjpeg%2F2018-11-03-ava-345_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FModibodi_Creative_1%2FModibodi_Creative_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FModibodi_Creative_1%2FModibodi_Creative_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FModibodi_Creative_1%2FModibodi_Creative_1.jpg",
    subKind: "creative",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs1-2017-10-08-avahayz-061%2Fjpegxl%2Fs1-2017-10-08-avahayz-061_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs1-2017-10-08-avahayz-061%2Fjpegxl%2Fs1-2017-10-08-avahayz-061_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs1-2017-10-08-avahayz-061%2Fjpegxl%2Fs1-2017-10-08-avahayz-061_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs1-2017-10-08-avahayz-061%2Fjpegxl%2Fs1-2017-10-08-avahayz-061_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs1-2017-10-08-avahayz-061%2Fjpeg%2Fs1-2017-10-08-avahayz-061_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs1-2017-10-08-avahayz-061%2Fjpeg%2Fs1-2017-10-08-avahayz-061_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs1-2017-10-08-avahayz-061%2Fjpeg%2Fs1-2017-10-08-avahayz-061_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs1-2017-10-08-avahayz-061%2Fjpeg%2Fs1-2017-10-08-avahayz-061_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs1-2017-10-08-avahayz-061%2Fjpeg%2Fs1-2017-10-08-avahayz-061_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDeering_Creative_1%2FDeering_Creative_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDeering_Creative_1%2FDeering_Creative_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FDeering_Creative_1%2FDeering_Creative_1.jpg",
    subKind: "creative",
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-814%2Fjpegxl%2F2018-11-03-ava-814_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-814%2Fjpegxl%2F2018-11-03-ava-814_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-814%2Fjpegxl%2F2018-11-03-ava-814_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-814%2Fjpegxl%2F2018-11-03-ava-814_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-814%2Fjpeg%2F2018-11-03-ava-814_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-814%2Fjpeg%2F2018-11-03-ava-814_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-814%2Fjpeg%2F2018-11-03-ava-814_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-814%2Fjpeg%2F2018-11-03-ava-814_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2F2018-11-03-ava-814%2Fjpeg%2F2018-11-03-ava-814_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FSeafolly_Ecomm_1%2FSeafolly_Ecomm_1.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FSeafolly_Ecomm_1%2FSeafolly_Ecomm_1.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FSeafolly_Ecomm_1%2FSeafolly_Ecomm_1.jpg",
    subKind: "ecomm",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fams_9117%2Fjpegxl%2Fams_9117_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fams_9117%2Fjpegxl%2Fams_9117_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fams_9117%2Fjpegxl%2Fams_9117_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fams_9117%2Fjpegxl%2Fams_9117_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fams_9117%2Fjpeg%2Fams_9117_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fams_9117%2Fjpeg%2Fams_9117_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fams_9117%2Fjpeg%2Fams_9117_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fams_9117%2Fjpeg%2Fams_9117_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fams_9117%2Fjpeg%2Fams_9117_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Creative_2%2FM.J_Bale_Creative_2.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Creative_2%2FM.J_Bale_Creative_2.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Creative_2%2FM.J_Bale_Creative_2.jpg",
    subKind: "creative",
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-148%2Fjpegxl%2Fs2-2017-10-08-avahayz-148_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-148%2Fjpegxl%2Fs2-2017-10-08-avahayz-148_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-148%2Fjpegxl%2Fs2-2017-10-08-avahayz-148_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-148%2Fjpegxl%2Fs2-2017-10-08-avahayz-148_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-148%2Fjpeg%2Fs2-2017-10-08-avahayz-148_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-148%2Fjpeg%2Fs2-2017-10-08-avahayz-148_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-148%2Fjpeg%2Fs2-2017-10-08-avahayz-148_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-148%2Fjpeg%2Fs2-2017-10-08-avahayz-148_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2017-10-08-avahayz-148%2Fjpeg%2Fs2-2017-10-08-avahayz-148_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_2%2FM.J_Bale_Ecomm_2.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_2%2FM.J_Bale_Ecomm_2.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_2%2FM.J_Bale_Ecomm_2.jpg",
    subKind: "ecomm",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2018-07-07-christi%20may-057_aaaaa%2Fjpegxl%2Fs2-2018-07-07-christi%20may-057_aaaaa_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2018-07-07-christi%20may-057_aaaaa%2Fjpegxl%2Fs2-2018-07-07-christi%20may-057_aaaaa_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2018-07-07-christi%20may-057_aaaaa%2Fjpegxl%2Fs2-2018-07-07-christi%20may-057_aaaaa_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2018-07-07-christi%20may-057_aaaaa%2Fjpegxl%2Fs2-2018-07-07-christi%20may-057_aaaaa_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2018-07-07-christi%20may-057_aaaaa%2Fjpeg%2Fs2-2018-07-07-christi%20may-057_aaaaa_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2018-07-07-christi%20may-057_aaaaa%2Fjpeg%2Fs2-2018-07-07-christi%20may-057_aaaaa_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2018-07-07-christi%20may-057_aaaaa%2Fjpeg%2Fs2-2018-07-07-christi%20may-057_aaaaa_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2018-07-07-christi%20may-057_aaaaa%2Fjpeg%2Fs2-2018-07-07-christi%20may-057_aaaaa_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs2-2018-07-07-christi%20may-057_aaaaa%2Fjpeg%2Fs2-2018-07-07-christi%20may-057_aaaaa_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_3%2FM.J_Bale_Ecomm_3.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_3%2FM.J_Bale_Ecomm_3.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_3%2FM.J_Bale_Ecomm_3.jpg",
    subKind: "creative",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs5-2017-10-08-avahayz-517%2Fjpegxl%2Fs5-2017-10-08-avahayz-517_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs5-2017-10-08-avahayz-517%2Fjpegxl%2Fs5-2017-10-08-avahayz-517_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs5-2017-10-08-avahayz-517%2Fjpegxl%2Fs5-2017-10-08-avahayz-517_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs5-2017-10-08-avahayz-517%2Fjpegxl%2Fs5-2017-10-08-avahayz-517_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs5-2017-10-08-avahayz-517%2Fjpeg%2Fs5-2017-10-08-avahayz-517_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs5-2017-10-08-avahayz-517%2Fjpeg%2Fs5-2017-10-08-avahayz-517_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs5-2017-10-08-avahayz-517%2Fjpeg%2Fs5-2017-10-08-avahayz-517_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs5-2017-10-08-avahayz-517%2Fjpeg%2Fs5-2017-10-08-avahayz-517_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs5-2017-10-08-avahayz-517%2Fjpeg%2Fs5-2017-10-08-avahayz-517_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 2,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_4%2FM.J_Bale_Ecomm_4.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_4%2FM.J_Bale_Ecomm_4.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FM.J_Bale_Ecomm_4%2FM.J_Bale_Ecomm_4.jpg",
    subKind: "ecomm",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-346_aaaaa%2Fjpegxl%2Fs4-2018-07-07-christi%20may-346_aaaaa_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-346_aaaaa%2Fjpegxl%2Fs4-2018-07-07-christi%20may-346_aaaaa_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-346_aaaaa%2Fjpegxl%2Fs4-2018-07-07-christi%20may-346_aaaaa_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-346_aaaaa%2Fjpegxl%2Fs4-2018-07-07-christi%20may-346_aaaaa_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-346_aaaaa%2Fjpeg%2Fs4-2018-07-07-christi%20may-346_aaaaa_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-346_aaaaa%2Fjpeg%2Fs4-2018-07-07-christi%20may-346_aaaaa_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-346_aaaaa_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-346_aaaaa_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-346_aaaaa_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FCommon_Hours_Ecomm_2%2FCommon_Hours_Ecomm_2.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FCommon_Hours_Ecomm_2%2FCommon_Hours_Ecomm_2.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FCommon_Hours_Ecomm_2%2FCommon_Hours_Ecomm_2.jpg",
    subKind: "ecomm",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2017-10-08-avahayz-473%2Fjpegxl%2Fs4-2017-10-08-avahayz-473_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2017-10-08-avahayz-473%2Fjpegxl%2Fs4-2017-10-08-avahayz-473_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2017-10-08-avahayz-473%2Fjpegxl%2Fs4-2017-10-08-avahayz-473_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2017-10-08-avahayz-473%2Fjpegxl%2Fs4-2017-10-08-avahayz-473_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2017-10-08-avahayz-473%2Fjpeg%2Fs4-2017-10-08-avahayz-473_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2017-10-08-avahayz-473%2Fjpeg%2Fs4-2017-10-08-avahayz-473_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2017-10-08-avahayz-473%2Fjpeg%2Fs4-2017-10-08-avahayz-473_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2017-10-08-avahayz-473%2Fjpeg%2Fs4-2017-10-08-avahayz-473_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2017-10-08-avahayz-473%2Fjpeg%2Fs4-2017-10-08-avahayz-473_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
  {
    type: "video",
    webmSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FSeafolly_Ecomm_3%2FSeafolly_Ecomm_3.webm",
    mp4Src:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FSeafolly_Ecomm_3%2FSeafolly_Ecomm_3.mp4",
    poster:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/videos_version_2024%2FSeafolly_Ecomm_3%2FSeafolly_Ecomm_3.jpg",
    subKind: "ecomm",
    shouldAutoPlayOnLoad: false,
    columnSpan: 1,
  },
  {
    type: "photo",
    jxlSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-304_aaaaa%2Fjpegxl%2Fs4-2018-07-07-christi%20may-304_aaaaa_400w.jxl 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-304_aaaaa%2Fjpegxl%2Fs4-2018-07-07-christi%20may-304_aaaaa_600w.jxl 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-304_aaaaa%2Fjpegxl%2Fs4-2018-07-07-christi%20may-304_aaaaa_800w.jxl 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-304_aaaaa%2Fjpegxl%2Fs4-2018-07-07-christi%20may-304_aaaaa_1000w.jxl 1000w",
    jpgSrc:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-304_aaaaa%2Fjpeg%2Fs4-2018-07-07-christi%20may-304_aaaaa_800w.jpg",
    jpgSrcSet:
      "https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-304_aaaaa%2Fjpeg%2Fs4-2018-07-07-christi%20may-304_aaaaa_400w.jpg 400w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-304_aaaaa%2Fjpeg%2Fs4-2018-07-07-christi%20may-304_aaaaa_600w.jpg 600w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-304_aaaaa%2Fjpeg%2Fs4-2018-07-07-christi%20may-304_aaaaa_800w.jpg 800w, https://storage.googleapis.com/alex-morrissey-smith-website.appspot.com/images_version_2024%2Fs4-2018-07-07-christi%20may-304_aaaaa%2Fjpeg%2Fs4-2018-07-07-christi%20may-304_aaaaa_1000w.jpg 1000w",
    loading: "lazy",
    kind: "photography",
    sizes: "(max-width: 1000px) 100vw, 1000px",
    columnSpan: 1,
  },
];
export default workContent;
